import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_SMART_VOUCHER_ASSIGNATION } from '../../connection.data';

@Injectable()
export class SmartVoucherAssignationService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_SMART_VOUCHER_ASSIGNATION;
    }
    unassign(item: any){
        return this.http.put(`${this.baseUrl}/unassign/${item.id}`, {});
    }

    getByVoucherBatch(voucherBatchId: number){
        return this.http.get(`${this.baseUrl}/voucher/batch/${voucherBatchId}`);        
    }
}